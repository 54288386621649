import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { withPrefix } from 'gatsby';
import PrivacyImage from '../components/privacyImage';

const Privacy = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - optimiert Ihre Praxis"
            googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
            fbUrl="https://medoboard.com"
            fbImage={withPrefix('medoboard-fb.png')}
            fbTitle="Medoboard - optimiert Ihre Praxis"
            fbDescription="Medoboard unterstützt Sie in ihrer täglichen Arbeit mit dem Patienten damit Sie mehr Zeit für das Wesentliche haben"
            keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]} />
        <Container>
            <Row className="carousel">
                <Col md="12">
                    <PrivacyImage />
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Datenschutzerklärung</h2>
                    <h4 className="text-muted">expressFlow GmbH</h4>
                </Col>
            </Row>
            <hr className="featurette-divider" />
            <Row>
                <Col md="12">
                    <p>
                        Der Schutz der persönlichen Daten unserer Interessenten, Kunden, Partner und Mitarbeiter ist uns ein besonderes
                        Anliegen. Wir verarbeiten diese Daten daher ausschließlich auf Grundlage der geltenden gesetzlichen Bestimmungen (EU-DSGVO, DSG 2018, TKG 2003).
                    </p>
                    <h3>Verantwortlicher/Datenschutzbeauftragter</h3>
                    <p>
                        Verantwortlicher gemäß Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung [„DSGVO“] ist die <br />
                        expressFlow GmbH <br />
                        1150 Wien <br />
                        Pater-Schwartz-Gasse 11A <br />
                        E-Mail: contact@expressFlow GmbH
                    </p>
                    <p>
                        Unseren Datenschutzbeauftragten erreichen Sie unter der E-Mail-Adresse: <br />
                        datenschutz@medoboard.com <br /> 
                        oder unter unserer Postanschrift mit dem Zusatz „zu Handen Datenschutzbeauftragter“.
                    </p>
                    <h3>Kontakt mit uns</h3>
                    <p>Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage bei uns gespeichert. Die Daten werden für eine dem Zweck und dem Inhalt der Anfrage entsprechende Dauer gespeichert. Wir geben keine Daten ohne Ihre Einwilligung weiter.</p>
                    <h3>Auftragsabwicklung</h3>
                    <p>Wir erheben personenbezogenen Daten (PD) bei der Anlage Ihres Interessenten- oder Kundenprofils. Die Auftragsabwicklung erfolgt über unser elektronisches Verwaltungssystem. Wir treffen alle technischen und organisatorischen Maßnahmen zur Datensicherheit.</p>
                    <h3>Website – Cookies</h3>
                    <p>Unsere Website verwendet sogenannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.</p>
                    <p>Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>
                    <p>Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben.</p>
                    <p>Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.</p>
                    <h3>Web-Analyse: Google Analytics</h3>
                    <p>Zur laufenden Verbesserung unseres Webauftritts nutzen wir den Dienst Google Analytics auf unseren Websites. Google Analytics ist von uns so eingestellt, dass die dabei erfassten IP-Adressen anonymisiert werden, also kein gezielter Rückschluss auf den einzelnen Website-Besucher erfolgen kann.</p>
                    <p>Sollten Sie diese Funktion als Besucher trotzdem deaktivieren wollen, können Sie dies über ein Add-on in Ihrem Browser einstellen: Google-Anleitung hier <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a></p>
                    <p>Die Nutzung des Dienstes beruht auf der Datenschutzerklärung von Google <a href="https://www.google.com/intl/de/policies/privacy/" target="_blank" rel="noopener noreferrer">https://www.google.com/intl/de/policies/privacy/</a>. Google hat sich nach dem US-Privacy Shield zertifiziert und entspricht damit den rechtlichen Bedingungen als Dienstleister aus einem Drittland nach EU-DSGVO.</p>
                    <h3>Email-Newsletter Versand</h3>
                    <p>Wir versenden Email-Newsletter an Interessenten und Kunden, um diese über unser Angebot zu informieren. Die Versendung erfolgt auf Basis der Einwilligung des Interessenten und aus dem Vertragsverhältnis als Kunde. Zur Durchführung des Versandes nutzen wir einen Email-Marketingdienst als Auftragsverarbeiter. Zu dem Zwecke werden nur folgende Daten übertragen: Name, Anrede und Email-Adresse. Beim Versand werden weiters Nutzungsdaten (Reportings) erhoben, diese dienen ausschließlich zur Verbesserung unseres Newsletter-Services und liegt in unserem berechtigten Interesse.</p>
                    <h3>Rechte der Betroffenen</h3>
                    <p>Wir weisen darauf hin, dass Sie jederzeit Ihr Recht geltend machen können bzgl. Auskunft, Übertragung, Berichtigung, Einschränkung, Widerruf und Löschung von Ihren personenbezogenen Daten. So nicht andere Rechtsgrundlagen dagegen sprechen, werden wir fristgerecht Ihrem Anliegen Folge leisten. Zuständige Behörde: Österreichische Datenschutzbehörde (<a href="http://www.dsb.gv.at/" target="_blank" rel="noopener noreferrer">www.dsb.gv.at</a>)</p>
                    <h3>Änderung dieser Datenschutzerklärung</h3>
                    <p>Wir behalten uns das Recht vor, diese Datenschutzerklärung anzupassen und zu aktualisieren, damit diese den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung angemessen abzubilden bzw. umzusetzen. Bei einem Besuch unserer Website bzw. der Inanspruchnahme unserer Leistungen gilt die jeweilige zu diesem Zeitpunkt aktuelle Version der Datenschutzerklärung.</p>
                </Col>
            </Row>
            <hr className="featurette-divider" />
        </Container>
    </Layout>
)

export default Privacy;